<template>
  <div class="main-box">
    <vue-element-loading
      :active="loading"
      :text="loadingText"
      spinner="bar-fade-scale"
      color="var(--secondary)"
    />
    <div class="flex-center flex-column h-100" v-if="dietPlanBtnList.length === 0">
      <no-data :show="true" class="flex-center flex-column h-100" message="هیچ برنامه غذایی برای زمین یافت نشد."/>
    </div>
    <CRow class="w-100" v-else>
      <CCol md="3" class="advice-wrapper">
        <div class="vertical-g-20">
          <ImageCard
            v-for="(item, index) of dietPlanBtnList"
            :key="index"
            @click="showDietPlanImage(item.image, item.id)"
            :image="getSrc(item.banner, 'Banner')"
            :active="showImageId === item.id"
            :loading="loading && showImageId === item.id"
          >
            <h6>{{ item.title }}</h6>
          </ImageCard>
        </div>
      </CCol>

      <CCol md="9" class="planInfoBox">
        <img :src="getSrc(showImage, 'Image')"  class="w-100" alt=""/>
      </CCol>
    </CRow>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import { apiUrlRoot } from "../constants/config";
import ImageCard from "../components/imageCard.vue";
import NoData from "../components/no-data.vue";
export default {
  name: "DietPlan",
  components: {NoData, ImageCard},
  props: ["farmId"],
  data() {
    return {
      loading: false,
      loadingText: "",
      dietPlanBtnList: [],
      showImage: "",
      showImageId: 0,
    };
  },
  methods: {
    ...mapActions("dashboard", ["GetFarmDietPlanByFarmId"]),
    getSrc(image, type) {
      if (image) return `${apiUrlRoot}/DietPlan/${type}/${image}`;
    },
    async fetch() {
      this.loading = true;
      this.loadingText = "دریافت برنامه غذایی زمین";
      const result = await this.GetFarmDietPlanByFarmId({
        farmId: this.farmId,
      });
      if (result.succeeded) {
        if (result.data.length > 0) {
          result.data.forEach((value, index) => {
            this.dietPlanBtnList.push({
              id: value.id,
              title: value.title,
              banner: value.banner,
              image: value.image,
            });
          });
          this.showImage = result.data[0].image;
          this.showImageId = result.data[0].id;
        }
      } else {
        this.$notify({
          title: "پیام سیستم",
          text: result.message,
          type: "error",
        });
      }
      this.loading = false;
    },
    showDietPlanImage(image, id) {
      this.showImage = image;
      this.showImageId = id;
    },
  },
  mounted() {
    this.fetch();
  },
};
</script>

<style scoped>
.main-box {
  height: 100dvh;
  overflow-y: auto;
}
.advice-wrapper {
  height: 80vh;
  overflow: auto;
  top: 9vh;
  position: sticky !important;
}
</style>
