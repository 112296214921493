<template>
  <div class="main-box">
    <vue-element-loading
      :active="loading"
      :text="loadingText"
      spinner="bar-fade-scale"
      color="var(--secondary)"
    />
    <div class="flex-center flex-column h-100" v-if="adviceBtnList.length === 0">
      <no-data :show="true" class="flex-center flex-column h-100" message="هیچ توصیه ای برای زمین یافت نشد."/>
    </div>
    <CRow v-else class="w-100">
      <CCol md="3" class="advice-wrapper">
        <div class="vertical-g-20">
          <ImageCard v-for="(item, index) of adviceBtnList"
                     :key="index"
                     @showDetail="showAdviceImage(item.id)" :active="adviceInfoId === item.id" :image="getSrc(item.image)"
                     :loading="imageLoading && adviceInfoId === item.id">
            <h6>{{ item.name }}</h6>
          </ImageCard>
        </div>
      </CCol>
      <CCol md="9">
        <vue-element-loading
          :active="imageLoading"
          text="دریافت اطلاعات توصیه..."
          spinner="bar-fade-scale"
          color="var(--secondary)"
        />
        <div v-html="adviceInfo" class="adviceInfoBox"></div>
      </CCol>
    </CRow>
  </div>
</template>

<script>
import {mapActions} from "vuex";
import {apiUrlRoot} from "../constants/config";
import NoData from "../components/no-data.vue";
import ImageCard from "../components/imageCard.vue";

export default {
  name: "AdviceCMP",
  components: {ImageCard, NoData},
  props: ["farmId"],
  data() {
    return {
      loading: false,
      imageLoading: false,
      loadingText: "در حال دریافت اطلاعات...",
      adviceBtnList: [],
      adviceInfo: "",
      adviceInfoId: 0,
    };
  },
  methods: {
    ...mapActions("dashboard", ["GetFarmAdviceByFarmId", "GetAdviceInfoById"]),
    getSrc(image) {
      if (image) return `${apiUrlRoot}/Advice/${image}`;
    },
    async fetch() {
      this.loading = true;
      this.loadingText = "دریافت توصیه های زمین";
      var result = await this.GetFarmAdviceByFarmId({
        farmId: this.farmId,
      });
      if (result.succeeded) {
        if (result.data.length > 0) {
          result.data.forEach((value, index) => {
            this.adviceBtnList.push({
              id: value.id,
              name: value.name,
              image: value.image,
            });
          });
          this.showAdviceImage(result.data[0].id);
        }
      } else {
        this.$notify({
          title: "پیام سیستم",
          text: `${result.message} <br /><br />`,
          type: "error",
        });
      }
      this.loading = false;
    },
    async showAdviceImage(id) {
      console.log({id})
      this.adviceInfoId = id;
      this.imageLoading = true;
      var result = await this.GetAdviceInfoById({id: this.adviceInfoId});
      this.imageLoading = false;
      if (result.succeeded) {
        this.adviceInfo = result.data.adviceInfo;
      } else {
        this.$notify({
          title: "پیام سیستم",
          text: "خطا در دریافت اطلاعات <br /><br />",
          type: "error",
        });
      }
    },
  },
  mounted() {
    this.fetch();
  },
};
</script>

<style scoped>
.main-box {
  height: 100vh;
  overflow-y: auto;
}


.advice-wrapper {
  height: 80vh;
  overflow: auto;
  top: 9vh;
  position: sticky !important;
}
</style>
