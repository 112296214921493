import { render, staticRenderFns } from "./DietPlan.vue?vue&type=template&id=388e9f39&scoped=true"
import script from "./DietPlan.vue?vue&type=script&lang=js"
export * from "./DietPlan.vue?vue&type=script&lang=js"
import style0 from "./DietPlan.vue?vue&type=style&index=0&id=388e9f39&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "388e9f39",
  null
  
)

export default component.exports